import { CACHE_KEY_FOLLOW_US_DIALOG } from '@/constants'
import { CreationInputAtom, CreationModeEnum, Creation, Persona, ErrorCodeEnum, Collection } from '@/types'
import { getLocalStorage } from '@/utils/base'
import { atom } from 'jotai'
import { nanoid } from 'nanoid'

export const jobsRefreshKeyAtom = atom<string>(nanoid())
export const showModeListAtom = atom<boolean>(false)
export const showCreationsAtom = atom<boolean>(false)

const isMobile = () => {
  if (typeof window === 'undefined') return false
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const creationInputAtom = atom<CreationInputAtom>({
  mode: CreationModeEnum.CreateHD,
  expanded: !isMobile(),
})

export const unFavouriteGenerationIdsAtom = atom<string[]>([])
export const allowedNSFWCreationIdsAtom = atom<string[]>([])

// storage latest data for single creation items, used for override list data without re-fetching all creations
// should be cleared when user navigates to other pages
export const creationDetailsAtom = atom<Record<string, Creation>>({})

export const creationColdDownAtom = atom<Date | null>(null)

export const showBlockedDialogAtom = atom<boolean>(false)

// deleted jobs
export const deletedCreationIdsAtom = atom<string[]>([])

export const hasBroadcastAtom = atom(false)

export const personaAtom = atom<Partial<Persona> | null>(null)

export const surveyCompleteAtom = atom<boolean | null>(null)

export const volumeAtom = atom<number>(1)
export const mutedAtom = atom<boolean>(false)

export const loginDialogOpenAtom = atom<boolean>(false)
export const getCreditDialogOpenAtom = atom<boolean>(false)

// export const followUsDialogOpenAtom = atom<boolean>(false)
// TODO: change this to false

export const followUsDialogOpenAtom = atom<boolean>(getLocalStorage<boolean>(CACHE_KEY_FOLLOW_US_DIALOG) ?? true)

export const getTobCreditDialogOpenAtom = atom<boolean>(false)
export const insufficientDialogOpenAtom = atom<boolean>(false)
export const versionDialogOpenAtom = atom<boolean>(false)

export const auth0SignInAtom = atom<boolean>(typeof window !== 'undefined' && !!localStorage.getItem('token'))
export const auth0Loading = atom<boolean>(true)
export const auth0FirstLoginAtom = atom<boolean | null>(null)

export const subscriptionDialogContentAtom = atom<{
  code?: ErrorCodeEnum
  message: string
} | null>(null)

export const creationInputPrivateModeAtom = atom<boolean>(false)

export const hideOnboardingAtom = atom<boolean>(false)

export const globalCollectionsAtom = atom<Collection[]>([])
