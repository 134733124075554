import type { InferenceSession, TypedTensor } from 'onnxruntime-web'
import type { FC, ReactElement } from 'react'

export type Nullable<T> = T | null
export type Nilable<T> = T | null | undefined

export interface ApiResponse<T = any> {
  status: string
  value: T
}

export interface ApiError {
  message: string
  code: string | number
  details: any[]
  days_to_expire: number
}

export type WithCreator<T> = {
  creator_id: string
  creator_name: string
} & T

export interface TimeFields {
  create_time: string
  update_time: string
}

export type WithTime<T> = TimeFields & T

export interface Pagination<T> {
  records: T[]
  total: number
  pageSize: number
  current: number
  next?: string
}

export type PaginationQuery<T> = T & {
  limit?: number
  page?: number
  collection_id?: string
  creation_mode?:
    | 'text2video'
    | 'text2image'
    | 'image2video'
    | 'upscale'
    | 'extend'
    | 'afc'
    | 'repainting'
    | 'inpainting'
  creation_type?: 'image' | 'video'
}

export interface Segment<T> {
  records: T[]
  next: string | null
}

export type SegmentQuery<T> = T & {
  limit?: number
  start?: string
  collection_id?: string
  creation_mode?:
    | 'text2video'
    | 'text2image'
    | 'image2video'
    | 'upscale'
    | 'extend'
    | 'afc'
    | 'repainting'
    | 'inpainting'
  creation_type?: 'image' | 'video'
}

export interface FirebaseFile {
  url: string | null
  filename: string | null
  size: number | null
  metadata: Record<string, string> | null
  thumbnailUrl: string | null
  thumbnailSize: number | null
  thumbnailMetadata: Record<string, string> | null
  coverUrl: string | null
  coverSize: number | null
  coverMetadata: Record<string, string> | null
}

export interface FirebaseUploadFile {
  url: string | null
  thumbnailUrl: string | null
  fileId: string
  thumbnailFileId: string
}

export interface InnerUploadFile {
  url: string // object-url
  thumbnailUrl: string // object-url
  fileId: string
  thumbnailFileId?: string
  width?: number
  height?: number
}

export type FirebaseFileList = FirebaseFile[]
export type FirebaseFileMap = Record<string, FirebaseFile>

export interface FirebaseProfile {
  uid: string
  email: string
  name: string
  avatar: string
}

export interface PublicProfile {
  user_id: string
  username: string
  avatar?: string
  email: string
  description?: string
  social_accounts?: {
    instagram?: string
    youtube?: string
    tiktok?: string
    xiaohongshu?: string
    x?: string
  }
  follower_count?: number
  following_count?: number
}

export interface Profile extends PublicProfile {
  create_time: string
  survey_completed?: boolean
  survey_completed_time?: string
}

export interface Me {
  id: string
}

export type AnyObject = Record<string, any>

export interface Creation extends BaseGenerationParams {
  model?: string
  type: 'generation' | 'inpainting' | 'repainting' | 'extend' | 'upscale'
  is_nsfw: boolean
  parent_id?: Nullable<string>
  is_illegal?: boolean
  input_type: InputType
  output_type: OutputType
  config: GenerationConfig
  create_time: string
  update_time: string
  output_video?: string
  outputs: {
    media_url: string
    thumbnail_url?: string
    like?: boolean
    id?: string
  }[]
  video_id?: string
  output_url?: string
  video_url?: string
  is_watermark_free_enabled?: boolean
  stream_url?: string
  is_public?: boolean // visibility
  is_published?: boolean // showcase
  is_collected?: boolean
  share_username?: string
  user_id?: string
  username?: string
  avatar?: string | null
  creation_id: string
  output_id?: string
  generation_id?: string
  thumbnail_url?: string
  commits: {
    collects_count?: number
    is_collect?: boolean
    is_like?: boolean
    is_dislike?: boolean
    likes_count?: number
    dislikes_count?: number
  }
  // for job status
  status: string
  progress?: number | null
  // deprecated
  feedback?: {
    like: number
  }
  settings?: GenerationSetting
}

export interface Generation {}

export type PromiseOrNot<T> = T | Promise<T>

export interface BaseGenerationParams {
  prompt?: string
  negative_prompt?: Nullable<string>
  settings?: GenerationSetting
}

export interface GenerationConfig {
  extend_duration?: number
  source_image?: Nullable<string>
  source_images?: Array<string | null>
  source_image_url?: Nullable<string>
  source_video?: Nullable<string>
  source_video_url?: Nullable<string>
  input_content_type?: Nullable<string>
  input_width?: Nullable<number>
  input_height?: Nullable<number>
  sam?: {
    clicks?: {
      coords: number[][]
      click_type: number[]
    }
  }
}

export interface GenerationParams extends BaseGenerationParams {
  config?: GenerationConfig
  parent_id?: Nullable<string>
}

export interface RepaintingParams extends BaseGenerationParams {
  config?: {
    source_video?: Nullable<string>
  }
}

export interface InpaintingParams extends BaseGenerationParams {
  config?: {
    source_video?: Nullable<string>
    clicks: [
      number[][], // coords
      number[], // click_type
    ]
  }
}

export interface GenerationResult {
  generation_id: string
}

export interface JobStatus {
  status: string
  progress: number | null
}

export type InputType = 'text' | 'image' | 'video'
export type OutputType = 'text' | 'image' | 'video'

export interface ShowcaseListItem {
  create_time: string
  input_type: InputType
  update_time: string
  user_id: string
  video_url: string
  thumbnail_url: string
  // output_video: string
  showcase_id: string
  username: string
}

export interface GenerationSetting {
  seed?: number // optional, default -1
  width?: number // optional, default 576
  height?: number // optional, default 320
  fps?: number // optional, default 8
  num_frames?: number // optional, default 16
  num_steps?: number // optional, default 20
  guidance_scale?: number // optional, default 7.5
  duration?: number // optional, default 2
  resoluton?: string // optional
  aspect_ratio?: string // optional, default 16/9
  resolution?: number
  motion_level?: number // motion level
  strength?: number // repaint strength
}

export interface ShowcaseDetail extends ShowcaseListItem {
  negative_prompt: string
  prompt: string
  settings: GenerationSetting
  type: string
}

export interface ShareItem extends Creation {
  share_username: string
}

export enum ErrorCodeEnum {
  AUTH_FIREBASE_TOKEN_EXPIRED = 'auth/id-token-expired',
  AUTH_INVALID_TOKEN = 'VB-100000',
  AUTH_TOKEN_EXPIRED = 'VB-100001',
  AUTH_BLOCKED = 'VB-100002',
  SHARE_NOT_FOUND = 'H-10002001',
  SHARE_ACCESS_DENIED = 'H-10002002',
  SHARE_PRIVATE = 'H-10003001',
  RATE_EXCEEDED = 'VB-100011',
  QUOTA_EXCEEDED = 'VB-100012',
  NOT_ALLOWED_PRIVATE_GENERATION = 'VB-100013',
  PAYMENT_FAILED = 'VB-100200',
  PRE_DELETE_ACCOUNT = 'VB-200000',
}

export type AnyFunction = (...args: any[]) => any

export type ExtendReturnType<F extends AnyFunction, T> = (...args: Parameters<F>) => ReturnType<F> & T

export interface Suggestion {
  key: string
  label: string
  prompt_append: string
  thumbnail: string
}

export interface CreationsAtom extends Segment<Creation> {}

export enum CreationModeEnum {
  CreateImg = 'CreateImg',
  Create = 'Create',
  CreateHD = 'CreateHD',
  Animate = 'Animate',
  AnimateHD = 'AnimateHD',
  Repaint = 'Repaint',
  Extend = 'Extend',
  Upscale = 'Upscale',
  // KeyframeConditioning = 'KeyframeConditioning',
}

export type UsecaseEnum = string

export type CreationModeEnumOrUsecaseEnum = CreationModeEnum | UsecaseEnum

export interface CreationInputAtom {
  creation?: Creation
  expanded?: boolean
  mode?: CreationModeEnumOrUsecaseEnum
  focusing?: boolean
  img?: string
}

export type VoidFunction = () => void
export type PromiseVoidFunction = () => Promise<void>
export type PromisableVoidFunction = VoidFunction | PromiseVoidFunction

export interface SamCallbackData extends SamToolbarProps {
  clicks?: any[] | null
  converted_video?: string
  svg?: string[] | null
  maskImgUrl?: string | null
}

export interface SamProps {
  className?: string
  imageUrl: string
  embeddingUrl: string
  onChange?: (data: SamCallbackData) => PoNVoid
  modelName?: string
  model: InferenceSession | null
  embeddingTensor: TypedTensor<'float32'> | null
}

export interface SamToolbarProps {
  mode: 'add' | 'remove'
  setMode?: (mode: 'add' | 'remove') => void

  canRedo: boolean
  redo?: PromisableVoidFunction

  canUndo: boolean
  undo?: PromisableVoidFunction

  canReset: boolean
  reset?: PromisableVoidFunction
}

export interface GetFirstFrameEmbeddingResp {
  // first_frame: string
  first_frame_url: string
  // embedding: string
  embedding_url: string
  converted_video?: string
}

export interface GetFirstFrameResp {
  // first_frame: string
  first_frame_url: string
  converted_video?: string
}

export interface SignedUploadURLResult {
  url: string // "https://xxxx", // This is signed url for upload
  key: string // "gs://xxx/xxx/xxx.{ext}" // This is the file URI for posting the job
}

export interface SignedUploadSpolightURLResult {
  video_url: string // "https://xxxx", // This is signed url for upload
  video_key: string // "gs://xxx/xxx/xxx.{ext}" // This is the file URI for posting the job
  thumbnail_url?: string // "https://xxxx", // This is signed url for upload
}

export interface UploadParams {
  name?: string
  file: File
  onProgress?: (data: { percent: number }) => void
  onSuccess?: (data: InnerUploadFile) => void
  onError?: (error: any) => void
}

export interface UploadToUrlParams extends UploadParams {
  url: string
  key: string
}

export type PoNVoid = PromiseOrNot<void>

export interface GlobalDialogProps {
  title: string
  content: string
  icon: any // react component
  onOk?: () => void
  onCancel?: () => void
}

export interface Broadcast {
  id: string
  start_time?: string
  end_time?: string
  title: string
  content: string
  link?: string
  disabled?: boolean
  frequency?: 'once' | 'session' | 'always'
  variant?: 'error' | 'info'
}

export interface CreationSettingConfig {
  cool_down_time?: number // optional, default 20, in seconds
  aspect_ratio_options?: Array<{
    label: string
    value: string
    icon: string
  }>
  styles?: Suggestion[]
}

export interface VideoTag {
  fixed: boolean
  label: string
  value: string
}

export type PersonaUsedFor = 'fun' | 'work'
export type PersonaAgeGroup = '0 ~ 18' | '18 ~ 25' | '26 ~ 35' | '36-50' | '50+'
export type PersonaRole =
  | 'Content Creator'
  | 'Programmer'
  | 'Artist'
  | 'Designer'
  | 'Marketer'
  | 'Film Maker'
  | 'Educator'
  | 'Other'
export type PersonaContentCategory =
  | 'Film/TV'
  | 'Sports'
  | 'Vlog / Live'
  | 'Performance'
  | 'News'
  | 'Animation'
  | 'Gaming'
  | 'Fashion & Beauty'
  | 'Learning'
  | 'Other'

export interface Persona {
  used_for: PersonaUsedFor
  nickname: string
  age_group: string
  source: string
  source_other?: string
  role: PersonaRole
  prefered_content_categories: PersonaContentCategory[]
  experienced_video_tools: boolean
  experienced_aigc_tools: string
  purpose?: string[]
  purpose_other?: string
  share?: string[]
  step?:
    | 'used_for'
    | 'nickname'
    | 'age_group'
    | 'role'
    | 'prefered_content_categories'
    | 'experienced_video_tools'
    | 'experienced_aigc_tools'
    | 'end'
}

export interface OnboardingData {
  type: 't2v' | 'i2v'
  prompt?: string
  submitted?: boolean
  creationId?: string
  status?: string
  file?: {
    url: string
    fileId?: string
    filename: string
    mimeType: string
  }
}

export interface Switches {
  onboarding_survey?: boolean
  tags?: boolean
  spotlight?: boolean // create by user
  follow?: boolean
  cdnvb4?: boolean // replace cdnvb1 to cdnvb4
  inpainting?: boolean // SAM inpainting
  keyframe_conditioning?: boolean // i2v keyframe conditioning
  sam?: boolean // SAM
}

export type Skipable<T> = T & {
  skip?: boolean
}

export interface SpotlightQuery {}

export interface SpotlightsListQuery {
  type: string | number
  start?: string | number
  limit: number
  page?: number
}

export interface Spotlight {
  spotlight_id: string
  user_id: string
  avatar?: string
  email: string
  username: string
  create_time?: string
  update_time?: string
  review_state?: 'published' | 'in_reviewed' | 'unqualified'
  heat_count?: number
  title: string
  description: string
  video_url: string
  stream_url: string
  thumbnail_url: string
  metadata?: {
    duration: number
    width: number
    height: number
  }
  social_accounts: {
    x?: string
    instagram?: string
    youtube?: string
    tiktok?: string
    xiaohongshu?: string
  }
  submit_email: string
  commits?: {
    is_like: boolean
    likes_count: number
  }
  tags: {
    id: string
    tagname: string
  }[]
}

export interface SpotlightFormData {
  title: string
  video_key: string
  description: string
  social_accounts?: {
    x?: string
    instagram?: string
    youtube?: string
    tiktok?: string
    xiaohongshu?: string
  }
  submit_email: string
}

export interface FAQ {
  question: string
  answer: string
}

export interface FAQConfig {
  spotlight: FAQ[]
  membership: FAQ[]
  api: FAQ[]
}

export interface CreationQuery {}

export interface CollectionQuery {
  limite: number
  page: number
}

export type GalleryItemSource = 'explore' | 'favorite' | 'profile' | 'creations' | 'collection'

export interface NavLink {
  href: string
  icon: FC<any>
  className?: string
  iconClassName?: string
  labelClassName?: string
  activeIcon?: FC<any>
  label: string | ReactElement
  key?: string
  event?: string
  target?: string
  onClick?: () => PoNVoid
}

export interface PlanFeature {
  name: string
  included: boolean
  tips?: string
}

export interface Plan extends Omit<StripePricing, 'prices'> {
  name: string
  is_free: boolean
  prices?: {
    monthly: number
    monthly_id?: string
    monthly_currency?: string
    yearly: number
    yearly_id?: string
    yearly_currency?: string
  }
  product_id?: string
  disabled?: boolean
  features: PlanFeature[]
}

export interface TobPlan extends TobSubscriptionPlanProduct {
  disabled?: boolean
  is_free: false
  features: PlanFeature[]
  product_id: string
  active_price?: StripePrice | null
  name: string
}

export interface TobTopupProduct {
  product: StripeProduct
  unit_amount: number
  currency: string
  prices: StripePrice[]
  exchange_to_720p_image: {
    unit_amount: number
    currency: string
    rate: number
  }
}

export interface TobSubscriptionPlanProduct {
  product: StripeProduct
  tier_id: string
  tier_name: string
  monthly_allocation_unit_amount: number
  monthly_allocation_currency: string
  prices: StripePrice[]
}

export interface TobPricingTable {
  top_up_products: TobTopupProduct[]
  subcription_products: TobSubscriptionPlanProduct[]
}

export type TimeStamp = number

export interface StripeProduct {
  id: string
  object: string
  active: boolean
  attributes: any[]
  created: TimeStamp
  default_price: Nullable<string>
  description: Nullable<string>
  images: any[]
  livemode: boolean
  marketing_features: any[]
  metadata: AnyObject
  name: string
  package_dimensions: Nullable<any>
  shippable: Nullable<any>
  statement_descriptor: Nullable<any>
  tax_code: string
  type: string
  unit_label: Nullable<any>
  updated: TimeStamp
  url: Nullable<string>
}

export interface StripeRecurring {
  aggregate_usage: Nullable<any>
  interval: string
  interval_count: number
  meter: Nullable<any>
  trial_period_days: Nullable<any>
  usage_type: string
}

export interface StripePrice {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: TimeStamp
  currency: string
  custom_unit_amount: Nullable<any>
  livemode: boolean
  lookup_key: Nullable<any>
  metadata: AnyObject
  nickname: Nullable<any>
  product: string
  recurring: StripeRecurring
  tax_behavior: string
  tiers_mode: Nullable<any>
  transform_quantity: Nullable<any>
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export interface StripePricing {
  tier_id: string
  tier_name: string
  product?: StripeProduct
  prices?: StripePrice[]
  max_queue_size: number
  max_concurrent_tasks: number
  max_generation_tasks: number
  allow_watermark_free_download: boolean
  allow_private_generation: boolean
}

export interface UserPlan extends Omit<StripePricing, 'prices' | 'product'> {
  active: boolean
  start_date: string
  end_date: string
  price: string
  product: string
  amount: number
  currency: string
  interval: BillingPeriod
  interval_count: number
}

export interface UserSubscription {
  subscriptions: StripeSubscription[]
  subscription_schedules: SubscriptionSchedules[]
  user_basic_info?: {
    customer_id?: string
    subscription?: {
      cancel_at_period_end?: boolean
      canceled_at?: Nullable<number>
      current_period_end?: string
      id?: string
      current_period_start?: string
      start_date?: string
      status?: StripeSubscription['status']
    }
    subscription_schedule?: SubscriptionSchedules
  }
  user_current_plan: Nullable<UserPlan>
  user_next_plan: Nullable<UserPlan>
}

export interface StripeSubscription {
  id: string
  object: string
  application: null
  application_fee_percent: null
  automatic_tax: StripeAutomaticTax
  billing_cycle_anchor: number
  billing_cycle_anchor_config: null
  billing_thresholds: null
  cancel_at: Nullable<number>
  cancel_at_period_end: boolean
  canceled_at: Nullable<number>
  cancellation_details: {
    comment: Nullable<string>
    feedback: Nullable<string>
    reason: Nullable<string>
  }
  collection_method: string
  created: number
  currency: string
  current_period_end: number
  current_period_start: number
  customer: string
  days_until_due: null
  default_payment_method: string
  default_source: null
  default_tax_rates: any[]
  description: null
  discount: null
  discounts: any[]
  ended_at: null
  invoice_settings: StripeInvoiceSettings
  items: StripeLines
  latest_invoice: string
  livemode: boolean
  metadata: StripeMetadata
  next_pending_invoice_item_invoice: null
  on_behalf_of: null
  pause_collection: null
  payment_settings: StripePaymentSettings
  pending_invoice_item_interval: null
  pending_setup_intent: null
  pending_update: null
  plan: StripePlan
  quantity: number
  schedule: null
  start_date: number
  status: 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | 'canceled' | 'unpaid' | 'paused'
  test_clock: null
  transfer_data: null
  trial_end: null
  trial_settings: {
    end_behavior: {
      missing_payment_method: string
    }
  }
  trial_start: null
}

interface StripeScriptionDataItem {
  id: string
  object: string
  billing_thresholds: null
  created: number
  amount?: number
  discounts: any[]
  metadata: {}
  plan: StripePlan
  price: StripePrice
  quantity: number
  subscription: string
  tax_rates: any[]
}

export interface StripeMetadata {}

interface StripePlan {
  id: string
  object: string
  active: boolean
  aggregate_usage: null
  amount: number
  amount_decimal: string
  billing_scheme: string
  created: number
  currency: string
  interval: string
  interval_count: number
  livemode: boolean
  metadata: StripeMetadata
  meter: null
  nickname: null
  product: string
  tiers_mode: null
  transform_usage: null
  trial_period_days: null
  usage_type: string
}

interface StripePaymentSettings {
  payment_method_options: {
    acss_debit: null
    bancontact: null
    card: {
      network: null
      request_three_d_secure: string
    }
    customer_balance: null
    konbini: null
    sepa_debit: null
    us_bank_account: null
  }
  payment_method_types: null
  save_default_payment_method: string
}

export type BillingPeriod = 'month' | 'year'

export interface SwitchSubscription {
  action: 'upgrade' | 'downgrade'
  current_plan: {
    interval: BillingPeriod
    price_id: string
    tier_id: string
  }
  data: StripeSwitchSubscription
  next_plan: {
    interval: BillingPeriod
    price_id: string
    tier_id: string
  }
  type: 'invoice'
}

export interface StripeSwitchSubscription {
  object: string
  account_country: string
  account_name: string
  account_tax_ids: null
  amount_due: number
  amount_paid: number
  amount_remaining: number
  amount_shipping: number
  application: null
  application_fee_amount: null
  attempt_count: number
  attempted: boolean
  automatic_tax: StripeAutomaticTax
  billing_reason: string
  charge: null
  collection_method: string
  created: number
  currency: string
  custom_fields: null
  customer: string
  customer_address: null
  customer_email: string
  customer_name: null
  customer_phone: null
  customer_shipping: null
  customer_tax_exempt: string
  customer_tax_ids: any[]
  default_payment_method: null
  default_source: null
  default_tax_rates: any[]
  description: null
  discount: null
  discounts: any[]
  due_date: null
  effective_at: null
  ending_balance: number
  footer: null
  from_invoice: null
  issuer: StripeIssuer
  last_finalization_error: null
  latest_revision: null
  lines: StripeLines
  livemode: boolean
  metadata: StripeMetadata
  next_payment_attempt: null
  number: null
  on_behalf_of: null
  paid: boolean
  paid_out_of_band: boolean
  payment_intent: null
  payment_settings: StripePaymentSettings
  period_end?: number
  current_period_end?: number
  period_start: number
  post_payment_credit_notes_amount: number
  pre_payment_credit_notes_amount: number
  quote: null
  receipt_number: null
  rendering: null
  shipping_cost: null
  shipping_details: null
  starting_balance: number
  statement_descriptor: null
  status: string
  status_transitions: StripeStatusTransitions
  subscription: string
  subscription_details: StripeSubscriptionDetails
  subscription_proration_date: number
  subtotal: number
  subtotal_excluding_tax: number
  tax: null
  test_clock: null
  total: number
  total_discount_amounts: any[]
  total_excluding_tax: number
  total_tax_amounts: any[]
  transfer_data: null
  webhooks_delivered_at: null
}
export interface StripeAutomaticTax {
  enabled: boolean
  liability: null
  status?: null
}
export interface StripeIssuer {
  type: string
}
export interface StripeLines {
  object: string
  data: StripeScriptionDataItem[]
  has_more: boolean
  total_count: number
  url: string
}

interface StripeStatusTransitions {
  finalized_at: null
  marked_uncollectible_at: null
  paid_at: null
  voided_at: null
}
interface StripeSubscriptionDetails {
  metadata: StripeMetadata
}

export interface SubscriptionSchedules {
  id: string
  object: string
  application: null
  canceled_at: null
  completed_at: null
  created: number
  current_phase: StripeCurrentPhase
  customer: string
  default_settings: StripeDefaultSettings
  end_behavior: string
  livemode: boolean
  metadata: StripeMetadata
  phases: StripePhasesItem[]
  released_at: null
  released_subscription: null
  renewal_interval: null
  status: string
  subscription: string
  test_clock: null
}
interface StripeCurrentPhase {
  end_date: number
  start_date: number
}
interface StripeDefaultSettings {
  application_fee_percent: null
  automatic_tax: StripeAutomaticTax
  billing_cycle_anchor: string
  billing_thresholds: null
  collection_method: string
  default_payment_method: null
  default_source: null
  description: null
  invoice_settings: StripeInvoiceSettings
  on_behalf_of: null
  transfer_data: null
}

interface StripeInvoiceSettings {
  account_tax_ids: null
  days_until_due?: null
  issuer: StripeIssuer
}

interface StripePhasesItem {
  add_invoice_items: any[]
  application_fee_percent: null
  billing_cycle_anchor: null
  billing_thresholds: null
  collection_method: null
  coupon: null
  currency: string
  default_payment_method: null
  default_tax_rates: any[]
  description: null
  discounts: any[]
  end_date: number
  invoice_settings: StripeInvoiceSettings | null
  items: StripeScriptionDataItem[]
  metadata: StripeMetadata
  on_behalf_of: null
  proration_behavior: string
  start_date: number
  transfer_data: null
  trial_end: null
}

export interface UseStripePlanCardActionParmas {
  // subscription: UserSubscription | null
  subscription?: {
    status: StripeSubscription['status'] | null
    product: string | null
    interval: BillingPeriod | null
    tier_id: string | null
    cancel_at_period_end: boolean | null
  }
  plan: Plan
  billingPeriod?: BillingPeriod
  onSubscribe?: (plan: Plan) => PoNVoid
  onRenew?: (plan: Plan) => PoNVoid
  onManagePayment?: () => PoNVoid
}

export interface UseTobStripePlanCardActionParmas {
  // subscription: UserSubscription | null
  customerInfo: TobCustomerInfo | null
  plan: TobPlan
  onSubscribe?: (plan: TobPlan) => PoNVoid
}

export interface CreditBalance {
  available_credits: number
}

export interface CreditPrice {
  active: boolean
  currency: string
  unit_amount: number
  id: string
}

export interface CreditTopupProduct {
  credits_per_unit: number
  prices: CreditPrice[]
  product: {
    active: boolean
    name: string
  }
}

export interface CreditSpendSku {
  amount: number
  id: number
  second?: number
  number?: number
  resolution?: number
}

export interface CreditSpendProduct {
  spu:
    | 'generation/txt2vid'
    | 'generation/img2vid'
    | 'inpainting/vid2vid'
    | 'repainting/vid2vid'
    | 'upscale/vid2vid'
    | 'extend/vid2vid'
    | 'generation/txt2img'
  skus: CreditSpendSku[]
  unit_amount: number
  unit_type: string
}

export interface CreditPricingTable {
  credit_topup_products: CreditTopupProduct[]
  credit_spend_products: CreditSpendProduct[]
}

export interface ExtendGenerationParams {
  prompt?: string
  negative_prompt?: string
  config: {
    source_video: string
    extend_duration?: number // default 4
  }
  parent_id: string
  is_public: boolean
}

export interface UpscaleGenerationParams {
  prompt?: string
  config: {
    source_video: string
  }
  settings?: GenerationSetting
  parent_id: string
  is_public: boolean
}

export interface LocalPreferences {
  creationsLayout?: 'grid' | 'list'
}

export interface SamAnnotation {
  x: number
  y: number
  width: number
  height: number
  clickType: number
}

export interface SamClick {
  x: number
  y: number
  width: null | number
  height: null | number
  clickType: number
}

export enum SamClickType {
  POSITIVE = 1.0,
  NEGATIVE = 0.0,
}

export interface SamModelScale {
  onnxScale: number
  maskWidth: number
  maskHeight: number
  scale: number
  uploadScale: number
  samScale?: any
  width: number
  height: number
}

export interface Collection {
  collection_id: string
  name: string
  description?: string
  number?: number
  create_time?: string
  update_time?: string
  user?: PublicProfile
  is_public?: boolean
  user_id?: string
  preview_urls?: string[]
}

export interface CollectionEditParam {
  collection_id: string
  name: string
  description?: Nullable<string>
  is_public: Boolean
}

export interface CollectionDetail extends Collection {
  creations: Creation[]
}
export interface ThreeStage<T> {
  first: T
  second: T
  third: T
}

export interface CreationMode {
  mode: CreationModeEnum
  Icon: FC<any>
  iconClassName?: string
  name: string
  intro: string
  tags: string[]
  badge?: string | null
  available?: boolean
  isNew?: boolean
  hidden?: boolean
  newFeature?: string
  isUpdated?: boolean
  membersOnly?: boolean
}

export interface TobCustomerBasicInfo {
  company?: string
  website?: string
  name?: string
  role?: string
  email?: string
  self_describe?: string
}

export interface TobStripeConnect {
  customer: string
  invoice: string
  price: string
  product: string
  subscription: string
}

export interface TobStripeSubscription {
  current_period_end: number
  current_period_start: number
  interval: BillingPeriod
  invoice_amount_paid: number
  invoice_status: 'paid'
}

export interface TobApiKey {
  client_id: string
  access_key: string
}

export interface TobCustomerInfo {
  _id?: string
  create_time?: string
  update_time?: string
  basic?: TobCustomerBasicInfo
  stripe_connect?: TobStripeConnect
  stripe_subscription?: TobStripeSubscription
  api_keys: TobApiKey[]
}

interface TobCreditsBasic {
  amount_unit: 'ms' | 'number' | 'usd'
  available_amount: number
  expirable_amount: number
  pending_settlement_amount: number
  permanent_amount: number
}

interface TobCreditsWithRate extends TobCreditsBasic {
  rate: number
}

export interface TobCredits extends TobCreditsBasic {
  amount_unit: 'ms'
  exchange_to_720p_image: TobCreditsWithRate
  exchange_to_1080p_image: TobCreditsWithRate
  exchange_to_usd: TobCreditsWithRate
}
export interface Size {
  width: number
  height: number
}

export type CommentWorkType = 'creation' | 'template' | 'spotlight'

export interface CommentBody {
  content: string
  work_id: string
  work_type: CommentWorkType
}

export interface Comment extends CommentBody {
  _id: string
  user_id: string
  user_name: string
  avatar: string
  redirect_url?: string
  create_time: string
  update_time: string
  likes: Array<{
    user_id: string
    liked_time: string
  }>
}

export interface TemplateExample {
  media_url: string
  thumbnail_url: string
}

export interface TemplateStyle {
  id: string
  label: string
  examples: TemplateExample[]
  params: AnyObject
}

export interface Template extends TimeFields {
  _id: string
  name: string
  author_id: string
  introduction: string
  version: number
  category: string
  examples: TemplateExample[]
  cover_image: string
  tags: string[]
  styles: TemplateStyle[]
}

export interface TemplateInputWidget {
  widget: 'text_input' | 'image_input' | 'video_input' | 'sam' | 'number_input' | 'radio'
  key: string
  name: string
  type: 'number' | 'string' | 'boolean'
  options: Array<{
    label: string
    value: string
  }>
}

export interface TemplateWorkflow extends TimeFields {
  _id: string
  inputs: TemplateInputWidget[]
}

export interface TemplateCreationMapping extends TimeFields {
  _id: string
  template_id: string
  creation_id: string
}

export interface Notification {
  _id: string
  type: 'video_like' | 'comment_like' | 'comment'
  message: {
    user_detail: {
      user_id: string
      user_name: string
      avatar: string
    }
    creation: {
      thumbnail_url: string
      creation_id: string
    }
    content: string
  }
  trigger_time: string
  is_read: boolean
  read_time: string | null
  metadata: AnyObject | null
}
