'use client'

import useBroadcasts from '@/hooks/useBroadcasts'
import { Alert } from '@lemonsqueezy/wedges'
import { cls, whisper } from '@/utils'
import IconError from '@haiper/icons-svg/icons/outline/error.svg'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import { NO_OUTLINE_STYLE, sidebarOffsetStyle } from '@/constants'
import { useEffect } from 'react'

export interface BroadcastsProps {
  className?: string
}

export default function Broadcasts({ className }: BroadcastsProps) {
  const { broadcasts, loading, markAsRead } = useBroadcasts()
  const firstBroadcast = broadcasts?.[0] ?? null
  const variant = firstBroadcast?.variant ?? 'error'

  const Icon = variant === 'error' ? IconError : IconInfo
  const iconClassName =
    variant === 'error' ? 'text-icon-critical' : 'text-icon-info'
  const backgroundClassName =
    variant === 'error'
      ? 'bg-surface-critical dark:bg-surface-hover'
      : 'bg-surface-info dark:bg-surface-info'
  const textClassName =
    variant === 'error'
      ? 'text-text-critical dark:text-text-critical'
      : 'text-text-info dark:text-text-info'

  useEffect(() => {
    whisper('broadcasts is: ', broadcasts)
  }, [broadcasts])
  if (!firstBroadcast || loading) {
    return null
  }

  return (
    <div
      className={cls(
        'z-20 w-full md:h-12 min-h-max md:min-h-max flex justify-center p-0',
        backgroundClassName,
        textClassName,
        className,
      )}
    >
      <div
        className={cls(
          'w-full max-w-[1728px] flex md:pr-16',
          // sidebarOffsetStyle,
        )}
      >
        <Alert
          closable
          className={cls(
            'z-50 w-0 flex-1 text-body-md leading-6 rounded-none max-w-[1728px]',
            backgroundClassName,
            textClassName,
            NO_OUTLINE_STYLE,
          )}
          before={
            <div>
              <Icon className={cls('min-w-6', iconClassName)} />
            </div>
          }
          color='error'
          title={
            <span>
              <span className={cls('text-heading-md font-bold', textClassName)}>
                {firstBroadcast.title}
              </span>
            </span>
          }
          onClose={() => markAsRead(firstBroadcast.id)}
        >
          <span className={cls('text-body-md dark:text-text-subdued')}>
            {firstBroadcast.content ?? null}
          </span>
        </Alert>
      </div>
    </div>
  )
}
